import React from "react";

const ProjectUnlockIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <path
      d='M20.5713 10.7176H7.17843V4.71763C7.17843 3.88996 7.85075 3.21763 8.67843 3.21763H15.3213C16.149 3.21763 16.8213 3.88996 16.8213 4.71763V6.53906C16.8213 6.65692 16.9177 6.75335 17.0356 6.75335H18.5356C18.6534 6.75335 18.7499 6.65692 18.7499 6.53906V4.71763C18.7499 2.82388 17.215 1.28906 15.3213 1.28906H8.67843C6.78468 1.28906 5.24986 2.82388 5.24986 4.71763V10.7176H3.42843C2.95432 10.7176 2.57129 11.1007 2.57129 11.5748V21.8605C2.57129 22.3346 2.95432 22.7176 3.42843 22.7176H20.5713C21.0454 22.7176 21.4284 22.3346 21.4284 21.8605V11.5748C21.4284 11.1007 21.0454 10.7176 20.5713 10.7176ZM19.4999 20.7891H4.49986V12.6462H19.4999V20.7891ZM11.2499 17.0658V18.4855C11.2499 18.6033 11.3463 18.6998 11.4641 18.6998H12.5356C12.6534 18.6998 12.7499 18.6033 12.7499 18.4855V17.0658C12.971 16.9071 13.136 16.6823 13.2212 16.4238C13.3064 16.1653 13.3074 15.8864 13.224 15.6273C13.1406 15.3682 12.9772 15.1422 12.7572 14.9819C12.5372 14.8216 12.2721 14.7352 11.9999 14.7352C11.7277 14.7352 11.4625 14.8216 11.2425 14.9819C11.0225 15.1422 10.8591 15.3682 10.7757 15.6273C10.6923 15.8864 10.6933 16.1653 10.7785 16.4238C10.8637 16.6823 11.0288 16.9071 11.2499 17.0658Z'
      fill='black'
      fillOpacity='0.85'
    />
  </svg>
);

export default ProjectUnlockIcon;

export const neutralOverrunDoseTooltips = {
  neutralWasteTooltip: "If appropriate, increase acid or caustic dose so that waste stream pH = 7.",
  overRunTooltip: "If appropriate, overrun WAC or WBA rest past its normal endpoint, affects weak/strong resin ratio.",
  doseOptimizationTooltip: "Adjust dose to minimum level required to meet all capacity and leakage targets.",
};

export const neutralOverrunDoseLabels = {
  neutralEffectLabel: {
    notRequired: "Not Required",
    required: "Required(will override dose inputs)",
  },
  overallComputationLabel: {
    mannual: "Manual",
    automatic: "Automatic",
  },
  doseOptimiozationLabel: {
    no: "No",
    yes: "Yes(will override dose inputs)",
  },
};

export const neutralOverrunDoseHeaders = {
  neutralWasteHeader: "Neutral Waste Effluent",
  overRunComputationHeader: "Overrun Computation",
  doseOptimizationHeader: "Dose Optimization",
};

export const ixEvalRegenerationLabel = {
  stepsLabel: "Steps:",
  or: "OR",
  dilutionFlowLabel: "Dilution Water Flow",
  totalSolutionFlowLabel: "Total Solution Flow",
  totalSolutionVolumeLabel: "Total Solution Volume",
  doseLabel: "Dose",
};

export const regenDoseControllerLabelsId = {
  chemicalFlowId: "CHEMICAL_FLOW",
  dilutionFlowId: "DILUTION_WATER_FLOW",
  totalSolutionFlowId: "TOTAL_SOLUTION_FLOW",
  totalSolutionVolumeId: "TOTAL_SOLUTION_VOLUME",
  concentrationId: "CONCENTRATION",
  doseId: "DOSE",
  regenTimeId: "TIME",
};

import { COAGULANT_CHEMICAL_MAPPING } from "@constants/chemical-symbol.constants";

import { capitalize } from "@utils/appUtils";
import DupontLogger from "@utils/DupontLogger";

import {
  COMPANY_CODE,
  INGE_MODULE_TR_RANGE_FACTOR,
  MODULE_COMPANY_NAMES,
  WATER_TYPES_LIMITS,
} from "./constants/UFConstants";

const Logger = DupontLogger("ufUtils");

export const isIngeSelected = ufTechnology => ufTechnology === 2;

export const filterProductCategories = items => items.filter(item => item.category !== "water");

// both module and backwash protocol should be UXA
export const isUxaSelected = ({ activeUFModule, uFBWProtocolID, bwProtocol }) => {
  const isBwProtocolUxa = bwProtocol
    ?.find(item => item.ufbwProtocolID === parseInt(uFBWProtocolID))
    ?.protocolName?.includes("UXA");

  const isModuleUxa = activeUFModule?.newModuleLongName?.includes("UXA");
  return isModuleUxa && isBwProtocolUxa;
};

export const filterModules = moduleList => {
  if (!moduleList) return {};

  return moduleList
    .filter(({ hide }) => !hide)
    .sort((a, b) => a.displayOrder - b.displayOrder)
    .reduce((acc, module) => {
      acc[module.companyName] = acc[module.companyName] || [];
      acc[module.companyName].push(module);
      return acc;
    }, {});
};

const isWaterTypeMatched = ({ waterType, feedWaterData }) => {
  const { turbidity, tss, toc } = feedWaterData;
  const { turbidityLimit, tssLimit, tocLimit } = WATER_TYPES_LIMITS[waterType] || {};
  return turbidity <= turbidityLimit && tss <= tssLimit && toc <= tocLimit;
};

export const getUFModulesByCompany = ({ ufCompany, waterID, ufModulesByCompany, feedWaterData }) => {
  Logger.log("getUFModulesByCompany", { ufCompany, waterID, ufModulesByCompany, feedWaterData });

  let ufModules = [];
  const waterType = waterID;
  if (ufCompany === COMPANY_CODE[1]) {
    ufModules = ufModulesByCompany[MODULE_COMPANY_NAMES[ufCompany]];
  } else {
    const isCriteriaMatched = isWaterTypeMatched({ waterType, feedWaterData });
    const INGE_MB_07_MODULES = ufModulesByCompany[MODULE_COMPANY_NAMES.INGE_MB_07] || [];
    const INGE_MB_09_MODULES = ufModulesByCompany[MODULE_COMPANY_NAMES.INGE_MB_09];
    const INGE_MB_15_MODULES = ufModulesByCompany[MODULE_COMPANY_NAMES.INGE_MB_15];
    // Below are Water Type IDs for different water types
    if (["2", "3", "4", "5", "6"].includes(`${waterType}`)) {
      ufModules = isCriteriaMatched ? [...INGE_MB_07_MODULES, ...INGE_MB_09_MODULES] : INGE_MB_09_MODULES;
    } else if (`${waterType}` === "7") {
      ufModules = isCriteriaMatched ? [...INGE_MB_09_MODULES, ...INGE_MB_15_MODULES] : INGE_MB_15_MODULES;
    } else if (`${waterType}` === "8") {
      ufModules = INGE_MB_15_MODULES;
    }
  }

  Logger.log("getUFModulesByCompany ufModules", { ufModules });

  return [...ufModules].sort((a, b) => a.displayOrder - b.displayOrder);
};

export const formatReportPayload = ufReport => {
  let Feed_coag_name = COAGULANT_CHEMICAL_MAPPING[ufReport.Feed_coag_name];
  if (!Feed_coag_name) {
    Feed_coag_name = ufReport.Feed_coag_name;
  }
  return {
    ufReport: { ...ufReport, Feed_coag_name },
    updatedChemical: ufReport.Feed_coag_name,
  };
};

export const formatUfRanges = range => ({
  soft: {
    min: range.softLowerLimit,
    max: range.softUpperLimit,
  },
  hard: {
    min: range.minValue,
    max: range.maxValue,
  },
});

export const getWaterTypeData = (id, waterTypes) =>
  waterTypes.find(({ waterTypeId }) => id?.toString() === waterTypeId?.toString());

export const getWaterSubTypeId = (waterSubTypesList, { turbidity, tss, toc }) => {
  if (waterSubTypesList.length === 0) return {};

  const fallback = waterSubTypesList[waterSubTypesList.length - 1];

  if (turbidity <= 0 && tss <= 0 && toc <= 0) {
    return waterSubTypesList.find(({ isDefault }) => isDefault) || fallback;
  }

  for (let i = 0; i < waterSubTypesList.length; i++) {
    let str = waterSubTypesList[i].description;
    str = str.replaceAll("≥", ">=");
    str = str.replaceAll("≤", "<=");
    str = str.replaceAll("<", "<");
    str = str.replaceAll(",", " &&");
    str = str.replaceAll("NTU", Number(turbidity));
    str = str.replaceAll("TSS", Number(tss));
    str = str.replaceAll("TOC", Number(toc));
    if (eval(str)) {
      return waterSubTypesList[i];
    }
  }
  return fallback;
};

export const getTRackModulesPerUnitRanges = (selectedTROption, activeUFModule) => {
  const trKey = capitalize(selectedTROption.toLowerCase());

  const softMin = activeUFModule[`module${trKey}Min`];
  const softMax = activeUFModule[`module${trKey}Max`];

  return {
    softLowerLimit: softMin,
    softUpperLimit: softMax,
    minValue: softMin,
    maxValue: softMax + INGE_MODULE_TR_RANGE_FACTOR[selectedTROption],
  };
};

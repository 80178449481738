import { UNITS } from "@constants/units.constant";

import { convertUptoDigits } from "@utils/appUtils";
import DupontLogger from "@utils/DupontLogger";

import { CEB_DETAILS_LIST } from "./UFDiagramConstants";

export const convertUpto2Digits = value => (isNaN(parseFloat(value)) ? "0.00" : parseFloat(value).toFixed(2));

const Logger = DupontLogger("UFDiagramHelper");
const FLOW_METRIC_UNIT = "m³/h";

const getCEBValue = (data, calcEngineData, chemicalListById, cebItem, convertUnitCallback) => {
  if (!data[cebItem.isEnabled]) return;

  const chemId = data[cebItem.chemicalId];
  const chemical = chemicalListById && chemicalListById[chemId];
  if (!chemical) {
    Logger.error(`getCEBValue - Chemical with ID ${chemId} not found in chemicalListById`);
    return;
  }

  const { displayName, bulkConcentration } = chemical;
  const unit = UNITS.MiligramPerLiter;
  const prefix = cebItem.prefix || "CEB";
  const chemValue = convertUnitCallback(calcEngineData[cebItem.calcField]);
  const chemBulkConentraion = convertUptoDigits(bulkConcentration, 0);
  const chemDetails = `${chemValue} ${chemBulkConentraion}${UNITS.percentage}`;
  const doseValue = convertUptoDigits(calcEngineData[cebItem.value] || data[cebItem.value], 0);

  return `${prefix} ${doseValue} ${unit} ${displayName} ${chemDetails}`;
};

export const getCEBChemicalDetails = (data, calcEngineData, chemicalListById, convertUnitCallback) => {
  // If UF Special Feature ID is 2 (mini-CIP only), filter out CEB details upfront
  const relevantDetailsList =
    String(data.ufSpecialFeatureID) === "2"
      ? CEB_DETAILS_LIST.filter(param => !param.key.includes("ceb"))
      : CEB_DETAILS_LIST;

  return relevantDetailsList.reduce((result, param) => {
    result[param.key] = getCEBValue(data, calcEngineData, chemicalListById, param, convertUnitCallback);
    return result;
  }, {});
};

export const getFeedPumpMaxFlow = (calcEngineData, unitConfig) => {
  let value = 0;
  const rawActualDay = calcEngineData?.flow_Feed_Pump_max;
  if (rawActualDay) {
    value = Math.round(+rawActualDay, 0);
  }
  return `Max ${value} ${unitConfig.selectedUnits[1]}`;
};

export const getPumpValues = (unitConfig, calcEngineData, pumpType, pressureValue, unitConversionByName) => {
  const flowUnit = unitConfig.selectedUnits[1];
  const pressureUnit = unitConfig.selectedUnits[3];
  let value = calcEngineData[pumpType] || 0;

  if (value && flowUnit !== FLOW_METRIC_UNIT) {
    value = unitConversionByName(value, flowUnit, FLOW_METRIC_UNIT);
  }

  return {
    value: formatFlowValue(value, flowUnit),
    pressure: formatPressureValue(pressureValue, pressureUnit),
  };
};

export const formatPressureValue = (pressureValue, pressureUnit) => {
  const fractionDigits = pressureUnit === UNITS.bar ? 1 : 0; //#139099
  const value = convertUptoDigits(pressureValue, fractionDigits);
  return `@${value} ${pressureUnit} (max)`;
};

export const formatFlowValue = (value, selectedUnit) => {
  const fractionDigits = selectedUnit === UNITS.literPerSecond ? 1 : 0; //#139099
  const formattedValue = convertUptoDigits(value, fractionDigits);
  return `${formattedValue} ${selectedUnit}`;
};

// #139099 Tank volumes should be rounded to next integer
export const formatVolumnValue = (value, unit) => {
  const fractionDigits = unit === UNITS.gallon ? 1 : 0;
  const result = fractionDigits === 0 && unit === UNITS.CUBIC_METER ? Math.ceil(value) : convertUptoDigits(value, fractionDigits);
  return `${result} ${unit}`;
};

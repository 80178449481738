import React from "react";

const ProjectLockCollaboratorIcon = ({ size = 64 }) => (
  <svg width={size} height={size} viewBox='0 0 64 64' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='32' cy='32' r='31.25' fill='white' stroke='#007672' strokeWidth='1.5' />
    <mask id='path-2-inside-1_7838_20984' fill='white'>
      <path d='M25.4189 23C25.4189 21.3431 26.7621 20 28.4189 20H36.3544C38.0113 20 39.3544 21.3431 39.3544 23V30.0645H25.4189V23Z' />
    </mask>
    <path
      d='M23.9189 23C23.9189 20.5147 25.9337 18.5 28.4189 18.5H36.3544C38.8397 18.5 40.8544 20.5147 40.8544 23H37.8544C37.8544 22.1716 37.1829 21.5 36.3544 21.5H28.4189C27.5905 21.5 26.9189 22.1716 26.9189 23H23.9189ZM39.3544 30.0645H25.4189H39.3544ZM23.9189 30.0645V23C23.9189 20.5147 25.9337 18.5 28.4189 18.5V21.5C27.5905 21.5 26.9189 22.1716 26.9189 23V30.0645H23.9189ZM36.3544 18.5C38.8397 18.5 40.8544 20.5147 40.8544 23V30.0645H37.8544V23C37.8544 22.1716 37.1829 21.5 36.3544 21.5V18.5Z'
      fill='#007672'
      mask='url(#path-2-inside-1_7838_20984)'
    />
    <rect x='20.75' y='30.8145' width='22.5' height='12.4355' rx='1.25' stroke='#007672' strokeWidth='1.5' />
    <path
      d='M32.0002 37.7823C33.0557 37.7823 33.9114 36.9266 33.9114 35.871C33.9114 34.8154 33.0557 33.9597 32.0002 33.9597C30.9446 33.9597 30.0889 34.8154 30.0889 35.871C30.0889 36.9266 30.9446 37.7823 32.0002 37.7823Z'
      stroke='#007672'
      strokeWidth='1.5'
    />
    <line x1='32.3633' y1='37.0322' x2='32.3633' y2='40.129' stroke='#007672' strokeWidth='1.5' />
  </svg>
);

export default ProjectLockCollaboratorIcon;

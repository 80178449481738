import API_URLS from "@constants/api.urls";

import {
  DeletedProjectsIcon,
  FilesIcon,
  FolderIcon,
  ImportedProjectsIcon,
  SharedProjectIcon,
  StarIcon,
} from "@components/SVGs";
import ExampleProjectIcon from "@components/SVGs/ExampleProject";
import HomeIcon from "@components/SVGs/HomeIcon";

import { HomeSideMenuStrings } from "../constants/HomeStrings";

export const HomeSideNavIds = {
  home: "home",
  allProjects: "all_projects",
  favProjects: "favourite_projects",
  importedProjects: "imported_projects",
  sharedProjects: "shared_projects",
  exampleProjects: "example_projects",
  deleted: "deleted_items",
  folders: "folders",
  folderProject: "folderProject",
};

export const HomeSideNavMenu = [
  {
    id: HomeSideNavIds.home,
    label: HomeSideMenuStrings.home,
    active: true,
    icon: <HomeIcon />,
  },
  {
    id: HomeSideNavIds.allProjects,
    label: HomeSideMenuStrings.allProjects,
    active: true,
    icon: <FilesIcon />,
  },
  {
    id: HomeSideNavIds.favProjects,
    label: HomeSideMenuStrings.favouriteProjects,
    active: true,
    icon: <StarIcon />,
  },
  {
    id: HomeSideNavIds.importedProjects,
    label: HomeSideMenuStrings.importtedProjects,
    active: true,
    icon: <ImportedProjectsIcon />,
  },
  {
    id: HomeSideNavIds.exampleProjects,
    label: HomeSideMenuStrings.exampleProjects,
    active: true,
    icon: <ExampleProjectIcon />,
  },
  {
    id: HomeSideNavIds.sharedProjects,
    label: HomeSideMenuStrings.sharedProjects,
    active: true,
    icon: <SharedProjectIcon />,
  },
  {
    id: HomeSideNavIds.deleted,
    label: HomeSideMenuStrings.deletedProjects,
    active: true,
    icon: <DeletedProjectsIcon />,
  },
  {
    id: HomeSideNavIds.folders,
    label: HomeSideMenuStrings.folders,
    active: true,
    icon: <FolderIcon />,
  },
];
export const HomeSidNavUrlMapping = {
  [HomeSideNavIds.home]: API_URLS.recentProjects,
  [HomeSideNavIds.allProjects]: API_URLS.allProjects,
  [HomeSideNavIds.favProjects]: API_URLS.projectFavorite,
  [HomeSideNavIds.exampleProjects]: API_URLS.exampleProject,
  [HomeSideNavIds.sharedProjects]: API_URLS.shareProject,
  [HomeSideNavIds.deleted]: API_URLS.projectDeleted,
  [HomeSideNavIds.folderProject]: API_URLS.folderProject,
  [HomeSideNavIds.importedProjects]: API_URLS.allProjects,
};

export const FoldersBreadcrumbData = [
  {
    id: HomeSideNavIds.home,
    label: HomeSideMenuStrings.home,
  },
  {
    id: HomeSideNavIds.folders,
    label: HomeSideMenuStrings.folders,
  },
];

import React from "react";

const ProjectLeaveCollaboratorIcon = ({ size = 64 }) => (
  <svg width={size} height={size} viewBox='0 0 64 64' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='32' cy='32' r='31.25' fill='white' stroke='#007672' strokeWidth='1.5' />
    <path
      d='M36.7457 38.5699H35.3046C35.1921 38.5699 35.0984 38.6636 35.0984 38.7761V40.4315H23.5648V23.5618H35.1011V25.2172C35.1011 25.3297 35.1948 25.4234 35.3073 25.4234H36.7484C36.8609 25.4234 36.9546 25.3324 36.9546 25.2172V22.5333C36.9546 22.0779 36.5877 21.7109 36.1323 21.7109H22.5362C22.0808 21.7109 21.7139 22.0779 21.7139 22.5333V41.4601C21.7139 41.9154 22.0808 42.2824 22.5362 42.2824H36.1296C36.585 42.2824 36.952 41.9154 36.952 41.4601V38.7761C36.952 38.6609 36.8582 38.5699 36.7457 38.5699Z'
      fill='#007672'
    />
    <path
      d='M39.0956 31.0322H30.6849C30.5671 31.0322 30.4706 31.1286 30.4706 31.2465V32.7465C30.4706 32.8644 30.5671 32.9608 30.6849 32.9608H39.0956V34.9965C39.0956 35.176 39.3019 35.2777 39.4439 35.1652L43.2448 32.1652C43.2704 32.1452 43.2911 32.1196 43.3053 32.0903C43.3196 32.0611 43.327 32.029 43.327 31.9965C43.327 31.964 43.3196 31.9319 43.3053 31.9026C43.2911 31.8734 43.2704 31.8478 43.2448 31.8277L39.4439 28.8277C39.3046 28.7152 39.0956 28.817 39.0956 28.9965V31.0322Z'
      fill='#007672'
    />
  </svg>
);

export default ProjectLeaveCollaboratorIcon;

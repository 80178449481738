import React from "react";

const ProjectOwnershipTransferIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <path
      d='M18.6163 7.25223C18.6163 3.58259 15.6431 0.609375 11.9734 0.609375C8.30378 0.609375 5.33056 3.58259 5.33056 7.25223C5.33056 9.47009 6.41806 11.4362 8.08949 12.6415C8.07878 12.6469 8.07074 12.6496 8.06538 12.6522C6.86806 13.1585 5.79396 13.8844 4.86985 14.8112C3.95108 15.7283 3.21962 16.8154 2.71628 18.0121C2.22105 19.1838 1.95377 20.4393 1.92878 21.7112C1.92806 21.7397 1.93308 21.7682 1.94353 21.7948C1.95397 21.8214 1.96964 21.8457 1.98961 21.8662C2.00958 21.8866 2.03345 21.9029 2.0598 21.914C2.08616 21.9251 2.11447 21.9308 2.14306 21.9308H3.74753C3.86271 21.9308 3.95913 21.8371 3.96181 21.7219C4.01538 19.654 4.84306 17.7174 6.30824 16.2496C7.82163 14.7308 9.83056 13.8951 11.9734 13.8951C15.6431 13.8951 18.6163 10.9219 18.6163 7.25223ZM11.9734 11.8594C9.42878 11.8594 7.36628 9.79687 7.36628 7.25223C7.36628 4.70759 9.42878 2.64509 11.9734 2.64509C14.5181 2.64509 16.5806 4.70759 16.5806 7.25223C16.5806 9.79687 14.5181 11.8594 11.9734 11.8594ZM14.7859 17.779H21.8573C21.9752 17.779 22.0716 17.6826 22.0716 17.5647V16.0647C22.0716 15.9469 21.9752 15.8504 21.8573 15.8504H17.1297L18.394 14.2406C18.4231 14.203 18.4391 14.1569 18.4395 14.1094C18.4395 13.9915 18.3431 13.8951 18.2252 13.8951H16.2806C16.1493 13.8951 16.0261 13.9567 15.9431 14.0585L14.1082 16.3915C13.9904 16.5415 13.9261 16.729 13.9261 16.9219C13.9288 17.396 14.3118 17.779 14.7859 17.779ZM21.2145 19.4933H14.1431C14.0252 19.4933 13.9288 19.5897 13.9288 19.7076V21.2076C13.9288 21.3254 14.0252 21.4219 14.1431 21.4219H18.8707L17.6065 23.0317C17.5773 23.0693 17.5613 23.1154 17.5609 23.1629C17.5609 23.2808 17.6573 23.3772 17.7752 23.3772H19.7198C19.8511 23.3772 19.9743 23.3156 20.0573 23.2138L21.8922 20.8808C22.01 20.7308 22.0743 20.5433 22.0743 20.3504C22.0716 19.8763 21.6886 19.4933 21.2145 19.4933Z'
      fill='black'
    />
  </svg>
);

export default ProjectOwnershipTransferIcon;

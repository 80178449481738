export const IXCommonStrings = {
  ixFeedIonErrorTitle: "Ion specification error",
  ixFeedIonErrorDescription: "For {treatmentName} system specifications, at least one ion should have non-zero value.",
  ixRegenerationErrorDescription: "The Regeneration Condition is not correctly specified.",
};

export const IXMenuPanelStrings = {
  ixInitialization: "IX Initialization",
  vesselRegeneration: "Vessel & Regeneration System",
  resinSelection: "Resin Selection",
  regenerationConditions: "Regeneration Conditions",
  advancedRegeneration: "Advanced Regeneration",
  equipment: "Equipment",
  productQuality: "Product Quality & Regenerant Dose",
  existingPlantDescription: "Existing Plant Description",
  finalParameterAdjustments: "Final Parameter Adjustments",
  evaluateProductQuality: "Product Quality",
  externalEquipment: "External Equipment",
};

export const IXInitializationStrings = {
  evaluateModelingObjective: "Evaluate Existing Plant",
  feedFlowRateAutomatic: "Automatic",
};

export const FinalParameterStrings = {
  useVesselGeometry: "Use Vessel Geometry and Resin Volume values",
  useVesselGeometryIgnoreResin: "Use Vessel Geometry values, Ignore Resin Volume values",
  ignoreVesselGeometryResinVol: "Ignore Vessel Geometry and Resin Volume values",
};

import { createSlice } from "@reduxjs/toolkit";

import { SharedProjectTabsIndexs } from "./constants/Home.constant";
import { HomeSortOption } from "./HomeFilters/constants/HomeFilters.constant";

const filterConfigInitialState = {
  technology: [],
  marketSegment: null,
};

const sortConfigInitialState = {
  key: "modifiedDate",
  order: "desc",
};

const initialState = {
  data: [],
  sharedProjects: null,
  sharedProjectsTab: SharedProjectTabsIndexs[0],
  loader: false,
  sbTechnology: [], // todo: move this to globalData ?
  flag: HomeSortOption.modifiedDate, // deprecate this with new sort dropdown change
  searchValue: "",
  sortConfig: sortConfigInitialState,
  filterConfig: filterConfigInitialState,
  isListView: false,
};

export const CardListSlice = createSlice({
  name: "cardlist",
  initialState,
  reducers: {
    updateProjectsList: (state, action) => {
      state.data = action.payload;
    },
    updateSharedProjects: (state, { payload }) => {
      state.sharedProjects = payload;
      if (payload) {
        state.data = payload.sharedByMe || [];
      }
    },
    updateLoader: (state, action) => {
      state.loader = action.payload;
    },
    updateSubTechnology: (state, action) => {
      state.sbTechnology = action.payload;
    },
    updateFlag: (state, action) => {
      state.flag = action.payload;
    },
    setSearchValue: (state, action) => {
      state.searchValue = action.payload;
    },
    updateSortConfig: (state, action) => {
      state.sortConfig = action.payload;
    },
    updateFilterConfig: (state, { payload }) => {
      state.filterConfig = { ...state.filterConfig, ...payload };
    },
    resetFilterConfig: state => {
      state.filterConfig = filterConfigInitialState;
    },
    resetSorConfig: state => {
      state.sortConfig = sortConfigInitialState;
    },
    setViewConfig: (state, action) => {
      state.isListView = action.payload;
    },
    updateProjectLoading: (state, { payload }) => {
      const { projectID, loadingMsg } = payload;
      const data = state.data.map(item => {
        if (item.projectID === projectID) {
          item.loadingMsg = loadingMsg;
        }
        return item;
      });
      state.data = data;
    },
    removeProjectFromList: (state, { payload }) => {
      const filterList = items => items.filter(item => item.projectID !== payload);
      const { sharedProjects, sharedProjectsTab } = state;
      if (sharedProjects) {
        state.sharedProjects[sharedProjectsTab] = filterList(sharedProjects[sharedProjectsTab]);
      } else {
        state.data = filterList(state.data);
      }
      return state;
    },
    updateProjectData: (state, { payload }) => {
      const { sharedProjects, sharedProjectsTab, data } = state;
      const { projectID } = payload;

      if (sharedProjects) {
        const sharedProjectsList = sharedProjects[sharedProjectsTab];
        const index = sharedProjectsList.findIndex(item => item.projectID === projectID);
        if (index !== -1) {
          sharedProjectsList[index] = payload;
        }
      }

      const dataIndex = data.findIndex(item => item.projectID === projectID);
      if (dataIndex !== -1) {
        data[dataIndex] = payload;
      }
    },
    updateProjectLockData: (state, { payload }) => {
      const { projectID, lockData } = payload;
      const { sharedProjects, sharedProjectsTab } = state;

      const updateItem = item => (item.projectID === projectID ? { ...item, ...lockData } : item);

      if (sharedProjects) {
        state.sharedProjects[sharedProjectsTab] = state.sharedProjects[sharedProjectsTab].map(updateItem);
      }
      state.data = state.data.map(updateItem);
    },
    updateShareProjectsTab: (state, { payload }) => {
      state.sharedProjectsTab = payload;
    },
  },
});

export const {
  updateProjectsList,
  updateSharedProjects,
  updateLoader,
  updateSubTechnology,
  updateFlag,
  setSearchValue,
  updateSortConfig,
  updateFilterConfig,
  resetFilterConfig,
  resetSorConfig,
  setViewConfig,
  updateProjectLockData,
  updateProjectLoading,
  updateShareProjectsTab,
  updateProjectData,
  removeProjectFromList,
} = CardListSlice.actions;

export default CardListSlice.reducer;

import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import WP_App_Routes from "src/Routes";

import { IX_TECHNOLOGIES, TECHNOLOGIES } from "@constants/global.constants";

import { updateActiveCase, updateProjectInfo } from "@common/ProjectInfoSlice";

import { useResetProjectData } from "./useResetReduxData";

const useProjectDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { resetProjectData } = useResetProjectData();

  const { data: userInfo } = useSelector(state => state.userInfo);
  const { data, projectData, projectChemicalCosts } = useSelector(state => state.projectInfo);
  const { countries } = useSelector(state => state.globalAppData);
  const { systemDesignCaseTreatmentVM } = useSelector(state => state.processDiagramSlice) || {};

  const { projectInfoVM, appInfoVM } = projectData;
  const chemicalListByCategory = projectChemicalCosts.chemicalListByCategory || {};
  const { caseID: activeCaseId, caseName } = useSelector(state => state.projectInfo?.activeCase || {});

  // this is an array on purpose because we can have multiple treatments in future
  const selectedTreatmentNames = useMemo(
    () => systemDesignCaseTreatmentVM.map(item => item.treatmentName),
    [systemDesignCaseTreatmentVM],
  );

  const PROJECT_TECH = useMemo(() => systemDesignCaseTreatmentVM?.[0]?.treatmentName, [systemDesignCaseTreatmentVM]);

  const IS_IX_PROJECT = useMemo(
    () => selectedTreatmentNames.some(name => IX_TECHNOLOGIES.includes(name)),
    [data, selectedTreatmentNames],
  );

  const activeTreatments = useMemo(() => systemDesignCaseTreatmentVM, [systemDesignCaseTreatmentVM]);

  const getCaseTreatmentId = treatmentName =>
    activeTreatments.find(item => item.treatmentName === treatmentName)?.caseTreatmentID;

  const IS_UF_PROJECT = useMemo(() => PROJECT_TECH === TECHNOLOGIES.UF, [PROJECT_TECH]);

  const countryName = useMemo(
    () => countries?.find(country => country.countryID == projectInfoVM?.countryID)?.countryName,
    [projectInfoVM?.countryID],
  );

  const navigatToProject = (projectData, activeCase) => {
    const projectState = {
      projectName: projectData.projectName,
      projectID: projectData.projectId,
      technologyName: "UF",
      isImported: false,
    };
    resetProjectData();
    dispatch(updateActiveCase(activeCase));
    dispatch(updateProjectInfo(projectState));
    navigate(WP_App_Routes.project, { state: projectState });
  };

  const projectDetails = useMemo(() => {
    const {
      projectNumber,
      projectName,
      createdDate,
      updatedDate,
      designer,
      designerCompany,
      customer,
      marketsegmentName,
      projectNotes,
      projectID,
      ownerId
    } = projectInfoVM || {};

    return {
      projectID,
      projectNumber,
      projectName,
      createdDate,
      updatedDate,
      designer,
      caseName,
      designerCompany,
      customer,
      countryName,
      marketsegmentName,
      projectNotes,
      appVersion: appInfoVM?.appVersion,
      isProjectOwner: userInfo?.UserId === ownerId
    };
  }, [projectInfoVM, appInfoVM?.appVersion, userInfo, caseName, countryName]);


  return {
    PROJECT_TECH,
    IS_UF_PROJECT,
    IS_IX_PROJECT,
    projectDetails,
    projectChemicalCosts,
    chemicalListByCategory,
    navigatToProject,
    getCaseTreatmentId,
    activeCaseId,
  };
};

export default useProjectDetails;

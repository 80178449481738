export const APP_TOAST_NOTIFICATION = {
  //=================== SUCCESS MESSAGES ==================================
  PROJECT_SAVED: "Project saved successfully",
  CHEMICAL_SAVED: "Chemical saved successfully",
  FOLDER_CREATED: "Folder created successfully",
  FOLDER_DELETED: "Folder deleted successfully",
  FOLDER_RESTORED: "Folder restored successfully",
  FOLDER_RENAMED: "Folder renamed successfully",
  PROJECT_DELETED: "Project deleted successfully",
  CHEMICAL_UPDATED: "Chemical updated successfully",
  CHEMICAL_DELETED: "Chemical deleted successfully",
  CASE_DELETED: "Case deleted successfully",
  CASE_ADDED: "Case added successfully",
  CASE_UPDATED: "Case updated successfully",
  PROJECT_RESTORED: "Project restored successfully",
  PROJECT_EXPORTED: "Project exported successfully",
  REPORT_DOWNLOADED: "Report downloaded successfully",
  WATER_LIB_SAVED: "Water library saved successfully",
  PROJECT_DUPLICATED: "Project duplicated successfully",
  WATER_LIB_REPLACED: "Water library replaced successfully",
  OPERATION_COSTS_UPDATED: "Operation costs updated successfully",
  DEFAULT_CHEMICAL_PRICE: "Default chemical prices updated successfully",
  DEFAULT_UTILITY_COST: "Default utility costs updated successfully",
  FAVORITE_ADDED: "Project added to Favorite Projects successfully",
  FAVORITE_REMOVED: "Project removed from Favorite Projects successfully",
  PROJECT_RENAMED: "Project renamed successfully",
  PROJECT_REMOVED_FROM_FOLDER: "Project removed from folder successfully",
  PROJECT_ADDED_TO_FOLDER: "Project added to folder successfully",
  PROJECT_COPY_SEND: "Project copy sent successfully",
  CURRENCY_AND_UNITS_UPDATION_APPLIED: "Updated preferences applied successfully",
  PUMPS_EDIT_SAVED: "Your edits are successfully saved",
  PROJECT_INFO_SAVED: "Your project information successfully saved",
  DEFAULT_SAVED: "Default values updated successfully",
  ACCOUNT_PREFRENCES_SAVED: "Account preferences saved successfully",
  ACCEPTED_TERMS: "Terms and Conditions accepted successfully",
  PROJECT_UNLOCKED: "Project is unlocked and ready for use by others",

  //=================== FAILED MESSAGES ==================================
  GET_PROJECT_FAILED: "Unable to load projects. Please try again",
  PROJECT_SAVED_FAILED: "Unable to save changes. Please try again",
  CHEMICAL_SAVE_FAILED: "Unable to save chemical. Please try again",
  PROJECT_EXPORT_FAILED: "Project not exported. Please try again",
  PROJECT_IMPORT_FAILED: "Project not imported. Please try again",
  WATER_LIB_GET_FAILED: "Unable to load library data",
  FOLDER_DELETE_FAILED: "Folder not deleted. Please try again",
  CHEMICAL_DELETE_FAILED: "Unable to delete chemical. Please try again",
  CHEMICAL_UPDATE_FAILED: "Unable to update chemical. Please try again",
  WATER_LIB_SAVE_FAILED: "Water library not saved. Please try again",
  PROJECT_DELETE_FAILED: "Unable to delete the project. Please try again",
  PROJECT_DUPLICATE_FAILED: "Project not duplicated. Please try again",
  REPORT_DOWNLOAD_FAILED: "Report not downloaded. Please try again",
  WATER_LIB_DELETE_FAILED: "Water library not deleted. Please try again",
  COPY_TO_FEED_WATER_FAILED: "Unable to copy to feed water. Please try again",
  WATER_LIB_REPLACE_FAILED: "Unable to replace library data. Please try again",
  CASE_UPDATE_FAILED: "The case could not be updated due to an error. Please try again",
  CASE_DELETE_FAILED: "The case could not be deleted due to an error. Please try again",
  OPERATION_COSTS_UPDATE_FAILED: "Unable to update project operation costs. Please try again",
  CASE_MOVE_FAILED: "Reordering failed. Please try again",
  CASE_ADD_FAILED: "Case addition failed. Please try again",
  FOLDER_NAME_ALREADY_EXISTS: "Duplicate folder name detected",
  DEFAULT_PROJECT_COST_FAILED: "Updating failed. Please try again",
  MOVE_PROJECT_TO_FOLDER_FAILED: "Project not moved to folder. Please try again",
  FOLDER_RESTORE_FAILED: "Folder not restored. Please try again",
  PROJECT_RESTORE_FAILED: "Project not restored. Please try again",
  FAVORITE_ADD_FAILED: "Project not added to favorite projects. Please try again",
  FAVORITE_REMOVE_FAILED: "Unable to remove project from favorite projects. Please try again",
  PROJECT_RENAME_FAILED: "Project rename failed. Please try again",
  PROJECT_REMOVE_FROM_FOLDER_FAILED: "Project not removed from folder. Please try again",
  PROJECT_ADD_TO_FOLDER_FAILED: "Project not added to folder. Please try again",
  PROJECT_SENT_FAILED: "Failed to send the project copy. Please try again",
  CURRENCY_AND_UNITS_UPDATION_FAILED: "Failed to apply preferences. Please try again",
  PUMPS_EDIT_FAILED: "Failed to save your edits. Please try again",
  PROJECT_INFO_SAVE_FAILED: "Failed to save project information. Please try again",
  FOLDER_RENAME_FAILED: "Folder rename failed. Please try again",
  UNABLE_TO_SAVE_ACCOUNT_PREFRENCES: "Unable to save account preferences. Please try again",
  DATA_FETCH_ERROR: "Unable to fetch data. Please try again",
  IMAGE_SIZE_ERROR: "Profile picture can't be more than {{size}} KB",
  IMAGE_TYPE_ERROR: "File type not supported. We accept JPG, JPEG and PNG format files only",
  ACCEPT_TERMS_FAILED: "Failed to accept terms and conditions. Please try again",
  /** Share Project Errors */
  GET_COLLABORATORS_FAILED: "Unable to retrieve collaborators data. Please try again",
  GET_PROJECT_HISTORY_FAILED: "Unable to retrieve project history. Please try again",
  ADD_COLLABORATOR_FAILED: "Unable to add collaborator. Please try again",
  TRANSFER_OWNERSHIP_FAILED: "Unable to transfer ownership. Please try again",
  REMOVE_COLLABORATOR_FAILED: "Unable to remove collaborator. Please try again",
  LEAVE_PROJECT_FAILED: "Unable to process request to leave project. Please try again",
  PROJECT_UNLOCK_FAILED: "Unable to unlock project. Refresh and try again",
  EXAMPLE_PROJECT_FAILED: "Duplicating sample project failed. Please try again",
};

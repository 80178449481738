// Public and restricted can be used in RO and other technologies

export const WATER_TYPE_FILTERS = [
  { category: "water", label: "Drinking Water", value: "drinking_water" },
  { category: "water", label: "Non-drinking Water", value: "non_drinking_water" },
];

export const RACK_TYPE_FILTERS = [
  { category: "rack", label: "Rack", value: "rack" },
  { category: "rack", label: "Open Platform", value: "open_platform" },
];

export const ADDITIONAL_FILTER_CATEGORY_TYPES = [
  { category: "obsolete", label: "Obsolete", value: "obsolete" },
  { category: "chinese", label: "China", value: "chinese" },
];

export const INGE_FILTER_CATEGORIES = [...RACK_TYPE_FILTERS, ...ADDITIONAL_FILTER_CATEGORY_TYPES];

export const DUPONT_FILTER_CATEGORIES = [
  ...RACK_TYPE_FILTERS,
  ...WATER_TYPE_FILTERS,
  ...ADDITIONAL_FILTER_CATEGORY_TYPES,
];

export const DEFAULT_DUPONT_FILTER_CATEGORIES = [...RACK_TYPE_FILTERS, ...WATER_TYPE_FILTERS];

export const DEFAULT_MODULE_FILTER_CATEGORIES = {
  1: DEFAULT_DUPONT_FILTER_CATEGORIES,
  2: RACK_TYPE_FILTERS,
};

export const MODULE_FILTER_CATEGORIES = {
  1: DUPONT_FILTER_CATEGORIES,
  2: INGE_FILTER_CATEGORIES,
};

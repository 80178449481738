import React from "react";

const ProjectLeftIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <path
      d='M16.4533 15.8377C17.1015 15.4895 17.8435 15.2913 18.6336 15.2913H18.6363C18.7167 15.2913 18.7542 15.1949 18.6952 15.1413C17.8735 14.4038 16.9348 13.8082 15.9176 13.3788C15.9068 13.3734 15.8961 13.3708 15.8854 13.3654C17.5488 12.1574 18.631 10.194 18.631 7.9788C18.631 4.30915 15.6631 1.33594 12.0015 1.33594C8.33988 1.33594 5.37471 4.30915 5.37471 7.9788C5.37471 10.194 6.45685 12.1574 8.12292 13.3654C8.1122 13.3708 8.10149 13.3734 8.09078 13.3788C6.89346 13.885 5.81935 14.6109 4.89524 15.5377C3.97647 16.4548 3.24501 17.542 2.74167 18.7386C2.24644 19.9104 1.97916 21.1658 1.95417 22.4377C1.95345 22.4663 1.95847 22.4948 1.96892 22.5214C1.97936 22.548 1.99504 22.5723 2.01501 22.5927C2.03498 22.6132 2.05884 22.6295 2.08519 22.6406C2.11155 22.6517 2.13986 22.6574 2.16845 22.6574H3.77292C3.8881 22.6574 3.98453 22.5636 3.9872 22.4484C4.04078 20.3806 4.86845 18.444 6.33363 16.9761C7.84703 15.4574 9.86131 14.6217 12.0042 14.6217C13.5229 14.6217 14.9801 15.0422 16.2363 15.8297C16.2686 15.85 16.3056 15.8614 16.3437 15.8628C16.3818 15.8642 16.4196 15.8556 16.4533 15.8377ZM12.0042 12.5859C10.7774 12.5859 9.62292 12.1065 8.75238 11.2359C8.32407 10.8087 7.98451 10.301 7.75328 9.74199C7.52204 9.18298 7.4037 8.58374 7.40506 7.9788C7.40506 6.74933 7.88453 5.59219 8.75238 4.72165C9.62024 3.85112 10.7747 3.37165 12.0042 3.37165C13.2336 3.37165 14.3854 3.85112 15.256 4.72165C15.6843 5.14886 16.0238 5.65659 16.2551 6.2156C16.4863 6.77461 16.6046 7.37385 16.6033 7.9788C16.6033 9.20826 16.1238 10.3654 15.256 11.2359C14.3854 12.1065 13.231 12.5859 12.0042 12.5859ZM21.856 18.9609H15.4274C15.3095 18.9609 15.2131 19.0574 15.2131 19.1752V20.6752C15.2131 20.7931 15.3095 20.8895 15.4274 20.8895H21.856C21.9738 20.8895 22.0702 20.7931 22.0702 20.6752V19.1752C22.0702 19.0574 21.9738 18.9609 21.856 18.9609Z'
      fill='black'
    />
  </svg>
);

export default ProjectLeftIcon;

import { FLOW_METRIC_UNIT } from "@constants/global.constants";

import { formatFlowValue } from "@components/SVGs/UFDiagrams/UFDiagramsUtils/UFDiagramHelper";

import { MODULE_TYPES } from "../constants/UFConstants";

import { MODULE_FILTER_CATEGORIES } from "./UFProductGuidelines/UFProductGuidelines.constant";

export const getUFStoreParams = (
  unit,
  unitTypeFlow,
  unitTypePressure,
  unitTypeFlux,
  unitTypePower,
  unitTypeGasFlow,
  unitTypeTemp,
) => [
  { key: "forwardFlushFlow", selectedUnitIdx: 1, unitType: unitTypeFlow },
  { key: "recycleFlowRate", selectedUnitIdx: 1, unitType: unitTypeFlow },
  { key: "recycleFlowRate_MiniCIP", selectedUnitIdx: 1, unitType: unitTypeFlow },

  { key: "nonIntegraPacTrainPresDrop", selectedUnitIdx: 3, unitType: unitTypePressure },
  { key: "integraPacFiltrationPreDrop", selectedUnitIdx: 3, unitType: unitTypePressure },
  { key: "integraPacFiltrationPreDrop", selectedUnitIdx: 3, unitType: unitTypePressure },
  { key: "backwashPipingPreDrop", selectedUnitIdx: 3, unitType: unitTypePressure },
  { key: "cIPPipingPreDrop", selectedUnitIdx: 3, unitType: unitTypePressure },
  { key: "backwash_Filtration", selectedUnitIdx: 3, unitType: unitTypePressure },
  { key: "acidCEB_Filtration", selectedUnitIdx: 3, unitType: unitTypePressure },
  { key: "alkaliCEB_Filtration", selectedUnitIdx: 3, unitType: unitTypePressure },
  { key: "cIP_Filtration", selectedUnitIdx: 3, unitType: unitTypePressure },
  { key: "maxAirScourPressure", selectedUnitIdx: 3, unitType: unitTypePressure },
  { key: "maxAirProcPressure", selectedUnitIdx: 3, unitType: unitTypePressure },
  { key: "filteratePressure", selectedUnitIdx: 3, unitType: unitTypePressure },
  { key: "recycleTemperature", selectedUnitIdx: 2, unitType: unitTypeTemp },
  { key: "filtrateFlux", selectedUnitIdx: 4, unitType: unitTypeFlux },
  { key: "backwashFlux", selectedUnitIdx: 4, unitType: unitTypeFlux },
  { key: "cEBFlux", selectedUnitIdx: 4, unitType: unitTypeFlux },

  { key: "pLCPowerReqPertrain", selectedUnitIdx: 9, unitType: unitTypePower },
  { key: "volvePowerReqPerTrain", selectedUnitIdx: 9, unitType: unitTypePower },

  { key: "airFlow", selectedUnitIdx: 18, unitType: unitTypeGasFlow },
  { key: "aerationAirFlow", selectedUnitIdx: 18, unitType: unitTypeGasFlow },
];
/**
 * Returns a numeric value from the given field.
 *
 * If the field is empty, null, or undefined, it returns 0.
 * If the field contains a non-numeric string, it returns NaN.
 *
 * @param {string|number} field - The input field to be converted to a number.
 * @returns {number} - The numeric value of the field or 0 if the field is empty.
 */
const getValue = field => {
  if (!field) return 0;
  const num = Number(field);
  return isNaN(num) ? 0 : num;
};

export const calculateUFFields = (stateData, activeUFModule) => {
  const online_Trains = getValue(stateData.onlineTrains);
  const redundantStandby_Trains = getValue(stateData.redundantStandbyTrains);
  const redundant_Trains = getValue(stateData.redundantTrains);
  const skidsPer_Trains = getValue(stateData.skidsPerTrain);
  const modulesPer_Skid = getValue(stateData.modulesPerSkid);
  const modulesPerTrain = getValue(stateData.modulesPerTrain);

  let TOTAL_UNITS = 0;
  const updatedData = {};

  if (stateData.uFBWCEBStandbyOptionID == 1) {
    TOTAL_UNITS = Math.round(online_Trains + redundant_Trains);
    updatedData.uFStorageTankOptionID = 2;
  } else {
    TOTAL_UNITS = Math.round(online_Trains + redundantStandby_Trains + redundant_Trains);
    updatedData.uFStorageTankOptionID = 1;
  }
  updatedData.totalTrains = TOTAL_UNITS;

  if (activeUFModule?.integraPacInd || activeUFModule?.tRack) {
    const MODULES_PER_UNIT = Math.round(skidsPer_Trains * modulesPer_Skid);
    updatedData.skids = Math.round(TOTAL_UNITS * skidsPer_Trains);
    let updatedModulesPerTrain = modulesPerTrain;
    if (activeUFModule?.integraPacInd) {
      updatedModulesPerTrain = MODULES_PER_UNIT;
    }
    updatedData.modulesPerTrain = updatedModulesPerTrain;
    updatedData.totalModules = Math.round(TOTAL_UNITS * updatedModulesPerTrain);
  } else {
    updatedData.skidsPerTrain = "1";
    updatedData.modulesPerSkid = "1";
    updatedData.skids = Math.round(TOTAL_UNITS);
    updatedData.totalModules = Math.round(TOTAL_UNITS * modulesPerTrain);
  }
  return updatedData;
};

// Ranges Validation
export const findRangeWithWater = (config, label) => config.find(item => item.label === label && item.waterSubType);
export const findRange = (config, label) => config.find(item => item.label === label);
export const provideRange = (label, fallbackRange, ufInputRangeConfigByWaterType, ufInputRangeConfig) => {
  const foundRange = findRangeWithWater(ufInputRangeConfigByWaterType, label) || findRange(ufInputRangeConfig, label);
  return foundRange || fallbackRange;
};

export const getFixedRange = (min, max, defaultVal) => ({ minValue: min, maxValue: max, defaultValue: defaultVal });

export const getModules = (arr, guidelines, technologyId) => {
  const categories = MODULE_FILTER_CATEGORIES[technologyId];

  return guidelines?.filter(guideline => {
    const unRelevantCategories = categories.filter(category => !arr?.some(item => item.value === category.value));

    return !unRelevantCategories.some(category => {
      switch (category.value) {
        case MODULE_TYPES.OBSOLETE:
          return guideline.productStatusName.trim() === "Obsolete";
        case MODULE_TYPES.CHINESE:
          return guideline.newModuleLongName.includes("China");
        case MODULE_TYPES.NON_DRINKING_WATER:
          return !guideline.drinkingWaterInd;
        case MODULE_TYPES.DRINKING_WATER:
          return guideline.drinkingWaterInd;
        case MODULE_TYPES.OPEN_PLATFORM:
          return !guideline.integraPacInd && !guideline.tRack && !guideline.memRack;
        case MODULE_TYPES.RACK:
          return guideline.integraPacInd || guideline.tRack || guideline.memRack;
        default:
          return false;
      }
    });
  });
};

export const handleSortingOperations = (sortOption, modulesList) => {
  let sortedFilterModulesData = [...modulesList];
  if (sortOption) {
    sortedFilterModulesData = [...modulesList].sort((a, b) =>
      sortOption === "asc"
        ? a.newModuleLongName.localeCompare(b.newModuleLongName)
        : b.newModuleLongName.localeCompare(a.newModuleLongName),
    );
  }
  return sortedFilterModulesData;
};

export const searchFilterLogic = (value, moduleList) => {
  const filteredData = moduleList?.filter(item => item.newModuleLongName.toLowerCase().includes(value.toLowerCase()));
  return filteredData;
};

export const getFiltrateValue = (filtrate, calcEngineData, unitConfig, unitConversionByName) => {
  const selectedUnit = unitConfig.selectedUnits[1];
  let value = (calcEngineData && calcEngineData[filtrate]) || 0;
  if (value && selectedUnit !== FLOW_METRIC_UNIT) {
    value = unitConversionByName(value, selectedUnit, FLOW_METRIC_UNIT);
  }
  value = (value && value / 24) || 0;
  return formatFlowValue(value, selectedUnit);
};

import React from "react";

const ProjectHistoryEmptyIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='94' height='94' viewBox='0 0 94 94' fill='none'>
    <circle opacity='0.3' cx='47' cy='47' r='47' fill='url(#paint0_linear_2924_64673)' />
    <g filter='url(#filter0_ddd_2924_64673)'>
      <path
        d='M50.4072 56.769C50.4072 65.1658 43.6003 65.9998 35.2036 65.9998C26.8069 65.9998 20 65.1658 20 56.769C20 48.3723 26.8069 41.5654 35.2036 41.5654C43.6003 41.5654 50.4072 48.3723 50.4072 56.769Z'
        fill='url(#paint1_linear_2924_64673)'
      />
      <circle cx='35.2037' cy='33.2398' r='7.23982' fill='white' />
    </g>
    <g filter='url(#filter1_ddd_2924_64673)'>
      <path
        d='M72.2081 57.6148C72.2081 67.0611 64.5504 67.9994 55.1041 67.9994C45.6578 67.9994 38 67.0611 38 57.6148C38 48.1685 45.6578 40.5107 55.1041 40.5107C64.5504 40.5107 72.2081 48.1685 72.2081 57.6148Z'
        fill='url(#paint2_linear_2924_64673)'
      />
      <circle cx='55.1038' cy='31.1448' r='8.1448' fill='white' />
    </g>
    <path
      d='M76.5549 59.6955L82.8549 61.2344C83.0558 61.2826 83.2527 61.1299 83.2527 60.925L83.2848 54.4362C83.2848 54.167 82.9754 54.0143 82.7665 54.183L76.4344 59.129C76.3865 59.166 76.3501 59.2158 76.3293 59.2726C76.3084 59.3294 76.3041 59.391 76.3167 59.4502C76.3292 59.5093 76.3583 59.5638 76.4004 59.6072C76.4426 59.6506 76.4961 59.6812 76.5549 59.6955ZM83.2929 71.7933L81.0147 71.0098C80.9353 70.9826 80.8484 70.9875 80.7725 71.0236C80.6967 71.0596 80.638 71.1239 80.6089 71.2027C80.5326 71.4076 80.4522 71.6085 80.3679 71.8094C79.6527 73.5009 78.6281 75.0237 77.3183 76.3295C76.023 77.6288 74.488 78.6644 72.7982 79.379C71.0477 80.1191 69.1661 80.499 67.2656 80.496C65.3451 80.496 63.4848 80.1223 61.733 79.379C60.0433 78.6644 58.5082 77.6288 57.2129 76.3295C55.9071 75.0237 54.8826 73.5009 54.1634 71.8094C53.4274 70.0579 53.0503 68.1766 53.0545 66.2768C53.0545 64.3563 53.4281 62.492 54.1714 60.7402C54.8866 59.0487 55.9112 57.5259 57.221 56.2201C58.5163 54.9208 60.0513 53.8851 61.7411 53.1705C63.4848 52.4272 65.3491 52.0536 67.2696 52.0536C69.1902 52.0536 71.0504 52.4272 72.8022 53.1705C74.492 53.8851 76.027 54.9208 77.3223 56.2201C77.7321 56.6339 78.1179 57.0638 78.4714 57.5179L80.8741 55.6375C77.7121 51.5955 72.7902 48.996 67.2616 49C57.6348 49.004 49.9045 56.8228 50.0009 66.4536C50.0973 75.9156 57.7915 83.5536 67.2696 83.5536C74.7228 83.5536 81.071 78.8286 83.4897 72.2112C83.55 72.0424 83.4616 71.8536 83.2929 71.7933Z'
      fill='#969696'
    />
    <g filter='url(#filter2_f_2924_64673)'>
      <circle cx='67.5' cy='66.5' r='14.5' fill='url(#paint3_linear_2924_64673)' fillOpacity='0.5' />
    </g>
    <path
      d='M65.3214 58H67.254C67.4308 58 67.5754 58.1446 67.5754 58.3214V68.2656L73.3049 72.408C73.4496 72.5085 73.4817 72.7094 73.3732 72.858L72.2241 74.425C72.1196 74.5696 71.9187 74.5978 71.7741 74.4933L65.1326 69.6437C65.0482 69.5835 65 69.4871 65 69.3826V58.3214C65 58.1446 65.1446 58 65.3214 58Z'
      fill='#969696'
    />
    <defs>
      <filter
        id='filter0_ddd_2924_64673'
        x='5.92931'
        y='15.447'
        width='58.5486'
        height='68.1414'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feColorMatrix
          in='SourceAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          result='hardAlpha'
        />
        <feMorphology radius='3.12682' operator='dilate' in='SourceAlpha' result='effect1_dropShadow_2924_64673' />
        <feOffset dy='3.51767' />
        <feGaussianBlur stdDeviation='5.47193' />
        <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0' />
        <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_2924_64673' />
        <feColorMatrix
          in='SourceAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          result='hardAlpha'
        />
        <feOffset dy='2.34511' />
        <feGaussianBlur stdDeviation='3.12682' />
        <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0' />
        <feBlend mode='normal' in2='effect1_dropShadow_2924_64673' result='effect2_dropShadow_2924_64673' />
        <feColorMatrix
          in='SourceAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          result='hardAlpha'
        />
        <feMorphology radius='1.56341' operator='erode' in='SourceAlpha' result='effect3_dropShadow_2924_64673' />
        <feOffset dy='1.17256' />
        <feGaussianBlur stdDeviation='1.17256' />
        <feColorMatrix type='matrix' values='0 0 0 0 0.0705882 0 0 0 0 0.513726 0 0 0 0 0.439216 0 0 0 0.12 0' />
        <feBlend mode='normal' in2='effect2_dropShadow_2924_64673' result='effect3_dropShadow_2924_64673' />
        <feBlend mode='normal' in='SourceGraphic' in2='effect3_dropShadow_2924_64673' result='shape' />
      </filter>
      <filter
        id='filter1_ddd_2924_64673'
        x='22.1705'
        y='11.1279'
        width='65.8671'
        height='76.6581'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feColorMatrix
          in='SourceAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          result='hardAlpha'
        />
        <feMorphology radius='3.51767' operator='dilate' in='SourceAlpha' result='effect1_dropShadow_2924_64673' />
        <feOffset dy='3.95738' />
        <feGaussianBlur stdDeviation='6.15592' />
        <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0' />
        <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_2924_64673' />
        <feColorMatrix
          in='SourceAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          result='hardAlpha'
        />
        <feOffset dy='2.63825' />
        <feGaussianBlur stdDeviation='3.51767' />
        <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0' />
        <feBlend mode='normal' in2='effect1_dropShadow_2924_64673' result='effect2_dropShadow_2924_64673' />
        <feColorMatrix
          in='SourceAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          result='hardAlpha'
        />
        <feMorphology radius='1.75884' operator='erode' in='SourceAlpha' result='effect3_dropShadow_2924_64673' />
        <feOffset dy='1.31913' />
        <feGaussianBlur stdDeviation='1.31913' />
        <feColorMatrix type='matrix' values='0 0 0 0 0.0705882 0 0 0 0 0.513726 0 0 0 0 0.439216 0 0 0 0.12 0' />
        <feBlend mode='normal' in2='effect2_dropShadow_2924_64673' result='effect3_dropShadow_2924_64673' />
        <feBlend mode='normal' in='SourceGraphic' in2='effect3_dropShadow_2924_64673' result='shape' />
      </filter>
      <filter
        id='filter2_f_2924_64673'
        x='50.4595'
        y='49.4595'
        width='34.0811'
        height='34.0811'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
        <feGaussianBlur stdDeviation='1.27027' result='effect1_foregroundBlur_2924_64673' />
      </filter>
      <linearGradient id='paint0_linear_2924_64673' x1='47' y1='0' x2='47' y2='94' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#6DD8CD' />
        <stop offset='1' stopColor='#E2F7F5' />
      </linearGradient>
      <linearGradient
        id='paint1_linear_2924_64673'
        x1='35.2036'
        y1='41.5654'
        x2='35.2036'
        y2='65.9998'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#F8F8F8' />
        <stop offset='1' stopColor='#E1E1E1' />
      </linearGradient>
      <linearGradient
        id='paint2_linear_2924_64673'
        x1='55.1041'
        y1='40.5107'
        x2='55.1041'
        y2='67.9994'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#F8F8F8' />
        <stop offset='1' stopColor='#E1E1E1' />
      </linearGradient>
      <linearGradient id='paint3_linear_2924_64673' x1='67.5' y1='52' x2='67.5' y2='81' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#F8F8F8' />
        <stop offset='1' stopColor='white' />
      </linearGradient>
    </defs>
  </svg>
);
export default ProjectHistoryEmptyIcon;

import React from "react";

const EmptyShareWithMeProjectsIcon = () => (
  <svg width='148' height='157' viewBox='0 0 148 157' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle opacity='0.3' cx='74' cy='74' r='74' fill='url(#paint0_linear_3035_123054)' />
    <g filter='url(#filter0_ddd_3035_123054)'>
      <path
        d='M46 38.4336C46 34.1113 49.5039 30.6074 53.8262 30.6074H91.3267C93.79 30.6074 96.1096 31.7672 97.5876 33.7379L103.066 41.0423L109.772 48.2453C111.121 49.6937 111.87 51.5992 111.87 53.5782V103.652C111.87 107.974 108.367 111.478 104.044 111.478H53.8262C49.5039 111.478 46 107.974 46 103.652V38.4336Z'
        fill='url(#paint1_linear_3035_123054)'
      />
      <path d='M59 53H99' stroke='white' strokeWidth='6' strokeLinecap='round' />
      <path d='M59 65H80' stroke='white' strokeWidth='6' strokeLinecap='round' />
      <path
        d='M91.6523 46.2611V32.4811C91.6523 30.7735 93.6865 29.8854 94.9389 31.0461L103.538 39.0158C104.524 39.9294 105.393 40.9614 106.125 42.0883L111.536 50.4123C112.382 51.7139 111.448 53.4351 109.895 53.4351H98.8264C94.8643 53.4351 91.6523 50.2232 91.6523 46.2611Z'
        fill='#E1E1E1'
      />
    </g>
    <circle opacity='0.3' cx='41.2' cy='111.2' r='13.2' fill='#969696' />
    <path
      d='M47.5147 115.183C47.5147 118.521 44.8085 118.853 41.4702 118.853C38.132 118.853 35.4258 118.521 35.4258 115.183C35.4258 111.845 38.132 109.139 41.4702 109.139C44.8085 109.139 47.5147 111.845 47.5147 115.183Z'
      fill='url(#paint2_linear_3035_123054)'
    />
    <circle cx='41.4682' cy='105.829' r='2.87831' fill='white' />
    <circle opacity='0.3' cx='48.5' cy='36.5' r='16.5' fill='#128370' />
    <path
      d='M56.3885 41.4774C56.3885 45.6503 53.0057 46.0647 48.8329 46.0647C44.6601 46.0647 41.2773 45.6503 41.2773 41.4774C41.2773 37.3046 44.6601 33.9219 48.8329 33.9219C53.0057 33.9219 56.3885 37.3046 56.3885 41.4774Z'
      fill='url(#paint3_linear_3035_123054)'
    />
    <circle cx='48.8284' cy='29.7854' r='3.59789' fill='white' />
    <circle opacity='0.3' cx='123.2' cy='78.2' r='13.2' fill='#AD87F7' />
    <path
      d='M129.515 82.1831C129.515 85.5214 126.808 85.853 123.47 85.853C120.132 85.853 117.426 85.5214 117.426 82.1831C117.426 78.8449 120.132 76.1387 123.47 76.1387C126.808 76.1387 129.515 78.8449 129.515 82.1831Z'
      fill='url(#paint4_linear_3035_123054)'
    />
    <circle cx='123.468' cy='72.8295' r='2.87831' fill='white' />
    <defs>
      <filter
        id='filter0_ddd_3035_123054'
        x='10'
        y='3.52148'
        width='137.871'
        height='152.957'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feColorMatrix
          in='SourceAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          result='hardAlpha'
        />
        <feMorphology radius='8' operator='dilate' in='SourceAlpha' result='effect1_dropShadow_3035_123054' />
        <feOffset dy='9' />
        <feGaussianBlur stdDeviation='14' />
        <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0' />
        <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_3035_123054' />
        <feColorMatrix
          in='SourceAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          result='hardAlpha'
        />
        <feOffset dy='6' />
        <feGaussianBlur stdDeviation='8' />
        <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0' />
        <feBlend mode='normal' in2='effect1_dropShadow_3035_123054' result='effect2_dropShadow_3035_123054' />
        <feColorMatrix
          in='SourceAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          result='hardAlpha'
        />
        <feMorphology radius='4' operator='erode' in='SourceAlpha' result='effect3_dropShadow_3035_123054' />
        <feOffset dy='3' />
        <feGaussianBlur stdDeviation='3' />
        <feColorMatrix type='matrix' values='0 0 0 0 0.0705882 0 0 0 0 0.513726 0 0 0 0 0.439216 0 0 0 0.12 0' />
        <feBlend mode='normal' in2='effect2_dropShadow_3035_123054' result='effect3_dropShadow_3035_123054' />
        <feBlend mode='normal' in='SourceGraphic' in2='effect3_dropShadow_3035_123054' result='shape' />
      </filter>
      <linearGradient id='paint0_linear_3035_123054' x1='74' y1='0' x2='74' y2='148' gradientUnits='userSpaceOnUse'>
        <stop stopColor='#6DD8CD' />
        <stop offset='1' stopColor='#E2F7F5' />
      </linearGradient>
      <linearGradient
        id='paint1_linear_3035_123054'
        x1='78.9352'
        y1='30.6074'
        x2='78.9352'
        y2='111.478'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#F8F8F8' />
        <stop offset='1' stopColor='#E1E1E1' />
      </linearGradient>
      <linearGradient
        id='paint2_linear_3035_123054'
        x1='41.4702'
        y1='109.139'
        x2='41.4702'
        y2='118.853'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#F8F8F8' />
        <stop offset='1' stopColor='#E1E1E1' />
      </linearGradient>
      <linearGradient
        id='paint3_linear_3035_123054'
        x1='48.8329'
        y1='33.9219'
        x2='48.8329'
        y2='46.0647'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#F8F8F8' />
        <stop offset='1' stopColor='#E1E1E1' />
      </linearGradient>
      <linearGradient
        id='paint4_linear_3035_123054'
        x1='123.47'
        y1='76.1387'
        x2='123.47'
        y2='85.853'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#F8F8F8' />
        <stop offset='1' stopColor='#E1E1E1' />
      </linearGradient>
    </defs>
  </svg>
);
export default EmptyShareWithMeProjectsIcon;

import React from "react";

const SaveIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
    <path
      d='M22.2138 6.14531L17.8585 1.78996C17.6576 1.58906 17.4111 1.44174 17.1433 1.36138V1.28906H2.14328C1.66917 1.28906 1.28613 1.6721 1.28613 2.14621V21.8605C1.28613 22.3346 1.66917 22.7176 2.14328 22.7176H21.8576C22.3317 22.7176 22.7147 22.3346 22.7147 21.8605V7.35603C22.7147 6.90067 22.5352 6.46674 22.2138 6.14531ZM8.57185 3.21763H15.429V6.00335H8.57185V3.21763ZM20.7861 20.7891H3.2147V3.21763H6.85756V6.86049C6.85756 7.3346 7.2406 7.71763 7.7147 7.71763H16.2861C16.7602 7.71763 17.1433 7.3346 17.1433 6.86049V3.80156L20.7861 7.44442V20.7891ZM12.0004 10.1283C9.87096 10.1283 8.14328 11.856 8.14328 13.9855C8.14328 16.115 9.87096 17.8426 12.0004 17.8426C14.1299 17.8426 15.8576 16.115 15.8576 13.9855C15.8576 11.856 14.1299 10.1283 12.0004 10.1283ZM12.0004 16.1283C10.8165 16.1283 9.85756 15.1694 9.85756 13.9855C9.85756 12.8016 10.8165 11.8426 12.0004 11.8426C13.1843 11.8426 14.1433 12.8016 14.1433 13.9855C14.1433 15.1694 13.1843 16.1283 12.0004 16.1283Z'
      fill='white'
    />
  </svg>
);

export default SaveIcon;

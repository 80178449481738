import React from "react";

const ProjectLockUnlockIcon = ({ isLocked }) => (
  <>
    {isLocked ? (
      <svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12' fill='none'>
        <path
          d='M9.75 5.4375H8.95312V2.8125C8.95312 1.98398 8.28164 1.3125 7.45312 1.3125H4.54688C3.71836 1.3125 3.04688 1.98398 3.04688 2.8125V5.4375H2.25C2.04258 5.4375 1.875 5.60508 1.875 5.8125V10.3125C1.875 10.5199 2.04258 10.6875 2.25 10.6875H9.75C9.95742 10.6875 10.125 10.5199 10.125 10.3125V5.8125C10.125 5.60508 9.95742 5.4375 9.75 5.4375ZM6.32812 8.21484V8.83594C6.32812 8.8875 6.28594 8.92969 6.23438 8.92969H5.76562C5.71406 8.92969 5.67188 8.8875 5.67188 8.83594V8.21484C5.57514 8.14539 5.50294 8.04704 5.46566 7.93394C5.42838 7.82084 5.42795 7.69883 5.46443 7.58547C5.50091 7.47211 5.57242 7.37325 5.66866 7.30312C5.7649 7.23299 5.88092 7.1952 6 7.1952C6.11908 7.1952 6.2351 7.23299 6.33134 7.30312C6.42758 7.37325 6.49909 7.47211 6.53557 7.58547C6.57205 7.69883 6.57162 7.82084 6.53434 7.93394C6.49706 8.04704 6.42486 8.14539 6.32812 8.21484V8.21484ZM8.10938 5.4375H3.89062V2.8125C3.89062 2.45039 4.18477 2.15625 4.54688 2.15625H7.45312C7.81523 2.15625 8.10938 2.45039 8.10938 2.8125V5.4375Z'
          fill='white'
        />
      </svg>
    ) : (
      <svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12' fill='none'>
        <path
          d='M9.75 5.4375H3.89062V2.8125C3.89062 2.45039 4.18477 2.15625 4.54688 2.15625H7.45312C7.81523 2.15625 8.10938 2.45039 8.10938 2.8125V3.60938C8.10938 3.66094 8.15156 3.70312 8.20312 3.70312H8.85938C8.91094 3.70312 8.95312 3.66094 8.95312 3.60938V2.8125C8.95312 1.98398 8.28164 1.3125 7.45312 1.3125H4.54688C3.71836 1.3125 3.04688 1.98398 3.04688 2.8125V5.4375H2.25C2.04258 5.4375 1.875 5.60508 1.875 5.8125V10.3125C1.875 10.5199 2.04258 10.6875 2.25 10.6875H9.75C9.95742 10.6875 10.125 10.5199 10.125 10.3125V5.8125C10.125 5.60508 9.95742 5.4375 9.75 5.4375ZM6.32812 8.21484V8.83594C6.32812 8.8875 6.28594 8.92969 6.23438 8.92969H5.76562C5.71406 8.92969 5.67188 8.8875 5.67188 8.83594V8.21484C5.57514 8.14539 5.50294 8.04704 5.46566 7.93394C5.42838 7.82084 5.42795 7.69883 5.46443 7.58547C5.50091 7.47211 5.57242 7.37325 5.66866 7.30312C5.7649 7.23299 5.88092 7.1952 6 7.1952C6.11908 7.1952 6.2351 7.23299 6.33134 7.30312C6.42758 7.37325 6.49909 7.47211 6.53557 7.58547C6.57205 7.69883 6.57162 7.82084 6.53434 7.93394C6.49706 8.04704 6.42486 8.14539 6.32812 8.21484V8.21484Z'
          fill='white'
        />
      </svg>
    )}
  </>
);

export default ProjectLockUnlockIcon;

import React from "react";

const UserIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12' fill='none'>
    <g clipPath='url(#clip0_3708_50853)'>
      <path
        d='M10.6402 9.36797C10.3876 8.76954 10.021 8.22596 9.56078 7.76752C9.10198 7.30776 8.55849 6.94119 7.96033 6.68806C7.95497 6.68538 7.94962 6.68404 7.94426 6.68136C8.77863 6.07868 9.32104 5.09699 9.32104 3.9894C9.32104 2.15458 7.83444 0.667969 5.99962 0.667969C4.16479 0.667969 2.67819 2.15458 2.67819 3.9894C2.67819 5.09699 3.2206 6.07868 4.05497 6.6827C4.04961 6.68538 4.04426 6.68672 4.0389 6.6894C3.4389 6.94252 2.90051 7.30547 2.43845 7.76886C1.97869 8.22766 1.61212 8.77114 1.35899 9.36931C1.11031 9.95492 0.976198 10.5828 0.963901 11.2189C0.963543 11.2332 0.96605 11.2474 0.971275 11.2607C0.976499 11.274 0.984334 11.2861 0.994319 11.2964C1.0043 11.3066 1.01624 11.3147 1.02941 11.3203C1.04259 11.3258 1.05675 11.3287 1.07104 11.3287H1.87461C1.93354 11.3287 1.98042 11.2818 1.98176 11.2242C2.00854 10.1903 2.42372 9.22199 3.15765 8.48806C3.91703 7.72868 4.92551 7.31083 5.99962 7.31083C7.07372 7.31083 8.0822 7.72868 8.84158 8.48806C9.57551 9.22199 9.99069 10.1903 10.0175 11.2242C10.0188 11.2831 10.0657 11.3287 10.1246 11.3287H10.9282C10.9425 11.3287 10.9566 11.3258 10.9698 11.3203C10.983 11.3147 10.9949 11.3066 11.0049 11.2964C11.0149 11.2861 11.0227 11.274 11.028 11.2607C11.0332 11.2474 11.0357 11.2332 11.0353 11.2189C11.0219 10.5787 10.8893 9.95592 10.6402 9.36797ZM5.99962 6.29297C5.38488 6.29297 4.80631 6.05324 4.37104 5.61797C3.93578 5.1827 3.69604 4.60413 3.69604 3.9894C3.69604 3.37467 3.93578 2.79609 4.37104 2.36083C4.80631 1.92556 5.38488 1.68583 5.99962 1.68583C6.61435 1.68583 7.19292 1.92556 7.62819 2.36083C8.06345 2.79609 8.30319 3.37467 8.30319 3.9894C8.30319 4.60413 8.06345 5.1827 7.62819 5.61797C7.19292 6.05324 6.61435 6.29297 5.99962 6.29297Z'
        fill='white'
      />
    </g>
    <defs>
      <clipPath id='clip0_3708_50853'>
        <rect width='12' height='12' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export default UserIcon;

import { CommonButtonStrings, EMPTY_STATE_STRINGS, FolderStrings, ProjectStrings } from "@utils/StringConstants";

import {
  DeleteProjectEmptyStateIcon,
  EmptyShareByMeProjectsIcon,
  EmptyShareWithMeProjectsIcon,
  FavEmptyStateIcon,
  FolderEmptyStateIcon,
  FolderProjectEmptyStateIcon,
  ImportProjectEmptyStateIcon,
  ProjectEmptyStateIcon,
  SearchProjectEmptyState,
} from "@components/SVGs";

export const TechImages = {
  UF: `${process.env.PUBLIC_URL}/images/Thumbnail_UF.svg`,
  IX: `${process.env.PUBLIC_URL}/images/Thumbnail_IX.svg`,
};

export const SharedProjectTabsIndexs = {
  0: "sharedWithMe",
  1: "sharedByMe",
};

const {
  all_projects,
  favourite_projects,
  imported_projects,
  deleted_items,
  folders,
  search_items,
  search_items_folder,
  folderProjectNotFound,
  search_deleted_items,
  projects_share_by_me,
  projects_share_with_me,
} = EMPTY_STATE_STRINGS;

export const PROJECT_SORT_CONFIG = {
  name: { key: "projectName", type: "string", useNumeric: true },
  createdDate: { key: "createDate", type: "date" },
  modifiedDate: { key: "lastModified", type: "date" },
};

// Configuration object for various empty states in the application
export const EMPTY_STATE_CONFIG = {
  home: {
    title: all_projects.title,
    description: all_projects.description,
    icon: ProjectEmptyStateIcon,
    buttonText: all_projects.buttonText,
  },
  all_projects: {
    title: all_projects.title,
    description: all_projects.description,
    icon: ProjectEmptyStateIcon,
    buttonText: all_projects.buttonText,
  },
  favourite_projects: {
    title: favourite_projects.title,
    description: favourite_projects.description,
    icon: FavEmptyStateIcon,
  },
  projects_shared_by_me: {
    title: projects_share_by_me.title,
    description: projects_share_by_me.description,
    icon: EmptyShareByMeProjectsIcon,
  },
  projects_shared_with_me: {
    title: projects_share_with_me.title,
    description: projects_share_with_me.description,
    icon: EmptyShareWithMeProjectsIcon,
  },
  imported_projects: {
    title: imported_projects.title,
    description: imported_projects.description,
    icon: ImportProjectEmptyStateIcon,
    buttonText: imported_projects.buttonText,
  },
  deleted_items: {
    title: deleted_items.title,
    description: deleted_items.description,
    icon: DeleteProjectEmptyStateIcon,
  },
  folders: {
    title: folders.title,
    description: folders.description,
    icon: FolderEmptyStateIcon,
    buttonText: folders.buttonText,
  },
  search_items: {
    title: search_items.title,
    description: search_items.description,
    icon: SearchProjectEmptyState,
  },
  search_items_folder: {
    title: search_items_folder.title,
    description: search_items_folder.description,
    icon: SearchProjectEmptyState,
  },
  folder_projects_not_found: {
    title: folderProjectNotFound.title,
    description: folderProjectNotFound.description,
    icon: FolderProjectEmptyStateIcon,
  },
  search_deleted_items: {
    title: search_deleted_items.title,
    description: search_deleted_items.description,
    icon: SearchProjectEmptyState,
  },
};

export const PROJECT_ACTIONS = {
  OPEN: "openProject",
  SEND: "sendProject",
  DELETE: "deleteProject",
  RENAME: "renameProject",
  EXPORT: "exportProject",
  UNLOCK: "unlockProject",
  RESTORE: "restoreProject",
  ADD_TO_FAV: "addToFavorite",
  ADD_TO_FOLDER: "addToFolder",
  DUPLICATE: "duplicateProject",
  RESTORE_FOLDER: "restoreFolder",
  COLLABORATORES: "collaborators",
  REMOVE_FROM_FAV: "removeFromFavorite",
  REMOVE_FROM_FOLDER: "removeFromFolder",
  PERMANENT_DELETE: "permanentDeleteProject",
  PERMANENT_DELETE_FOLDER: "permanentDeleteFolder",
  EXAMPLE_PROJECTS: "exampleProjects",
};

export const FOLDER_ACTIONS = {
  OPEN: "openFolder",
  DELETE: "deleteFolder",
  RENAME: "renameFolder",
};

export const HOME_ACTIONS = {
  ACCOUNT_PREFERENCES: "accountPreferences",
  TERMS_AND_CONDITIONS: "termsAndConditions",
  CREATE_PROJECT: "createProject",
  IMPORT_PROJECT: "importProject",
  CREATE_FOLDER: "createFolder",
};

export const DELETED_FOLDER_MENUS = [
  {
    id: PROJECT_ACTIONS.RESTORE_FOLDER,
    label: FolderStrings.restoreFolder,
  },
  {
    id: PROJECT_ACTIONS.PERMANENT_DELETE_FOLDER,
    label: ProjectStrings.permanentlyDelete,
  },
];

export const DELETED_PROJECT_MENUS = [
  {
    id: PROJECT_ACTIONS.RESTORE,
    label: ProjectStrings.restoreProject,
  },
  {
    id: PROJECT_ACTIONS.PERMANENT_DELETE,
    label: ProjectStrings.permanentlyDelete,
  },
];

export const COLLABORATOS_PROJECT_MENUS = [
  {
    id: PROJECT_ACTIONS.OPEN,
    label: CommonButtonStrings.openProject,
  },
  {
    id: PROJECT_ACTIONS.DUPLICATE,
    label: ProjectStrings.duplicateProject,
  },
  {
    id: PROJECT_ACTIONS.COLLABORATORES,
    label: ProjectStrings.addCollaborators,
  },
  {
    id: PROJECT_ACTIONS.UNLOCK,
    label: ProjectStrings.unlockProject,
  },
];

export const PROJECT_MENUS = [
  {
    id: PROJECT_ACTIONS.OPEN,
    label: CommonButtonStrings.openProject,
  },
  {
    id: PROJECT_ACTIONS.ADD_TO_FAV,
    label: ProjectStrings.addToFavorite,
  },
  {
    id: PROJECT_ACTIONS.REMOVE_FROM_FAV,
    label: ProjectStrings.removeFromFavorite,
  },
  {
    id: PROJECT_ACTIONS.ADD_TO_FOLDER,
    label: CommonButtonStrings.addToFolder,
  },
  {
    id: PROJECT_ACTIONS.REMOVE_FROM_FOLDER,
    label: ProjectStrings.removeFromFolder,
  },
  {
    id: PROJECT_ACTIONS.DUPLICATE,
    label: ProjectStrings.duplicateProject,
  },
  {
    id: PROJECT_ACTIONS.COLLABORATORES,
    label: ProjectStrings.addCollaborators,
  },
  {
    id: PROJECT_ACTIONS.DELETE,
    label: ProjectStrings.deleteProject,
  },
  {
    id: PROJECT_ACTIONS.RENAME,
    label: ProjectStrings.renameProject,
  },
  {
    id: PROJECT_ACTIONS.EXPORT,
    label: ProjectStrings.exportProject,
  },
  {
    id: PROJECT_ACTIONS.SEND,
    label: ProjectStrings.sendProject,
  },
  {
    id: PROJECT_ACTIONS.UNLOCK,
    label: ProjectStrings.unlockProject,
  },
];

export const FOLDER_MENUS = [
  {
    id: FOLDER_ACTIONS.OPEN,
    label: FolderStrings.openFolder,
  },
  {
    id: FOLDER_ACTIONS.DELETE,
    label: FolderStrings.deleteFolder,
  },
  {
    id: FOLDER_ACTIONS.RENAME,
    label: FolderStrings.renameFolder,
  },
];

import API_URLS, { EXTERNAL_URLS } from "@constants/api.urls";

import DupontLogger from "@utils/DupontLogger";

import { postRequest } from "../baseApi";

const Logger = DupontLogger("Report API");

export const getUfCalcReport = reqBody => postRequest(API_URLS.ufCalcReport, reqBody);

/**
 * Sends engagement data as a form data to the specified URL.
 * formId, owningOrganization, engagementType, engagementSubType, webVisitorID are fixed 
 * and Shared by AEM team
 * 
 * @param {Object} payload - The payload containing engagement data.
 */
export const reportDownloadEngagement = payload => {
    const data = {
        ...payload,
        formId: "1001",
        owningOrganization: "WS",
        engagementType: "Download",
        engagementSubType: "Document",
        webVisitorID: "0000-0000-0000",
        engagementURL: location.origin,
        engagementSource: `WAVE PRO ${process.env.REACT_APP_PROJECT_ENV}`
    };

    const formData = new FormData();
    for (const key in data) {
        formData.append(key, data[key]);
    }

    fetch(EXTERNAL_URLS.engagementURL, { method: "POST", body: formData })
        .then(response => {
            if (!response.ok) {
                Logger.error(`EngagementURL - Report HTTP error! status: ${response.status}`);
            }
        })
        .catch(error => {
            Logger.error("EngagementURL - Report error:", error);
        });
    return;
};
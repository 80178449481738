import { regenDoseControllerLabelsId } from "./IXEvaluate.strings";
const {
  chemicalFlowId,
  dilutionFlowId,
  totalSolutionFlowId,
  totalSolutionVolumeId,
  concentrationId,
  doseId,
  regenTimeId,
} = regenDoseControllerLabelsId;

const overRunComputationId = {
  mannualId: 0,
  automaticId: 1,
};

const neutralWasteEffluentId = {
  notRequiredId: 0,
  requiredId: 1,
};

const doseOptimizationId = {
  notRequiredDoseId: 0,
  requiredDoseId: 1,
};

export const neutralOverrunDoseRadioId = { ...overRunComputationId, ...neutralWasteEffluentId, ...doseOptimizationId };

export const InitialExistingPlantValue = {
  resinVolumeAsDelivered: 0,
  vesselDiameter: 0,
  resinBedHeightAsDelivered: 0,
  resinBedStandardHeight: 0,
  resinBedHeightAsExhausted: 0,
  resinBedHeightAsRegenerated: 0,
  inertResinVolume: 0,
  inertBedHeight: 0,
  freeBoard: 0,
  vesselCylindricalHeight: 0,
  vesselWallThickness: 0,
  pressureDropwithRecomQty: 0,
  resinPackagingSize: 0,
};

export const regenerantDoseConst = {
  chemicalFlow: {
    name: chemicalFlowId,
    values: [0],
  },
  dilutionWaterFlow: {
    name: dilutionFlowId,
    values: [0],
  },
  totalSolutionFlow: {
    name: totalSolutionFlowId,
    values: [0],
  },
  totalSolutionVolume: {
    name: totalSolutionVolumeId,
    values: [0],
  },
  dose: {
    name: doseId,
    values: [1],
  },
  time: {
    name: regenTimeId,
    values: [0],
  },
  concentration: {
    name: concentrationId,
    values: [1],
  },
};
export const EvalProtocolOneRegen = {
  regenerantID: 0,
  chemicalID: 0,
  temperatureID: 0,
  temperature: 0,
  serviceWater: null,
  backwash: null,
  evaluateRegenCondId: 0,
  regenProtocol: 0,
  selectedPropertyName: doseId,
  regenerantDose: regenerantDoseConst,
};

export const regenOptionMapping = {
  CHEMICAL_FLOW: 1,
  TOTAL_SOLUTION_FLOW: 2,
  TOTAL_SOLUTION_VOLUME: 3,
  DOSE: 4,
};

export const enableDisabledRegenerantField = {
  CHEMICAL_FLOW: [chemicalFlowId, dilutionFlowId, regenTimeId],
  TOTAL_SOLUTION_FLOW: [totalSolutionFlowId, regenTimeId, concentrationId],
  TOTAL_SOLUTION_VOLUME: [totalSolutionVolumeId, regenTimeId, concentrationId],
  DOSE: [doseId, concentrationId],
};

export const regenerantAdditionalFields = [
  {
    label: "Time (min)",
    values: ["", "", ""],
    id: regenTimeId,
    controller: "time",
  },
  {
    label: "Concentration(%)",
    values: ["", "", ""],
    id: concentrationId,
    controller: "concentration",
  },
];

export const regenDoseFieldsValues = ["", "", ""];

export const regenerantDoseDefaultValue = {
  CHEMICAL_FLOW: 1,
  DILUTION_WATER_FLOW: 1,
  TOTAL_SOLUTION_FLOW: 1,
  TOTAL_SOLUTION_VOLUME: 1,
  DOSE: 1,
  CONCENTRATION: 1,
  TIME: 30,
};
